import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/ops-dashboard/layouts/base-layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/containers/ErrorPageContent/ErrorPageContent.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/layouts/BaseLayout.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ops-dashboard/components/Sidebar.tsx");

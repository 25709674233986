// assets
import internetServerErrorImage from "@/assets/images/wrong-way.png";
import notFoundImage from "@/assets/images/notfound.png";
import tokenExpirationImage from "@/assets/images/traffic.png";

export const ERROR_PAGE_CONTENT = {
  "/500": {
    header: "500",
    description: "Internal server error",
    subDescription: null,
    image: internetServerErrorImage,
    meta: null
  },
  "/403": {
    header: "403",
    description: "Access forbidden",
    subDescription: null,
    image: internetServerErrorImage,
    meta: null
  },
  "/404": {
    header: "404",
    description: "Error 404: This isn’t the space we were hoping you’d find",
    subDescription: "The page you were looking for couldn’t be found.",
    image: notFoundImage,
    meta: <meta name="prerender-status-code" content="404"/>
  },
  "/401": {
    header: "401",
    description: "Oops! Your activation link has expired",
    subDescription: null,
    image: tokenExpirationImage,
    meta: <meta name="prerender-status-code" content="404"/>
  },
  "/invalid-token": {
    header: null,
    description: "Invalid token",
    subDescription: null,
    image: internetServerErrorImage,
    meta: <meta name="prerender-status-code" content="401"/>,
  },
  "/coming-soon": {
    header: <>lo<span style={{ color: "rgb(254, 97, 97)" }}>:</span>ading</>,
    description: "We’re just putting the finishing touches to our Australian marketplace and will be going live very soon. In the meantime, click below to brief us!",
    subDescription: "",
    image: notFoundImage,
    meta: null
  }
};

export const MESSAGE_ERROR = "Account is activated already.";
export const SUCCESS_ERROR = "We have sent new confirmation link to your account.";
